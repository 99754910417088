<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "Consulting",

  metaInfo: { title: "Consulting" },

  extends: View,

  mixins: [
    LoadSections([
      "consulting-description",
      "alternating-features",
      "newsletter",
      "info-alt",
    ]),
  ],

  props: {
    id: {
      type: String,
      default: "consulting",
    },
  },
};
</script>
